import { AuthGuardService } from './services/guards/auth-guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'usuario/recuperar-senha',
        loadChildren: () => import('./recuperar-senha/enviar-codigo/enviar-codigo.module').then(m => m.RecuperarSenhaEnviarCodigoPageModule)
    },

    {
        path: 'usuario/alterar-senha',
        loadChildren: () => import('./recuperar-senha/alterar-senha/alterar-senha.module').then(m => m.RecuperarSenhaAlterarSenhaPageModule)
    },

    {
        path: 'usuario/login',
        loadChildren: () => import('./usuario/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'usuario/registro/:acao',
        loadChildren: () => import('./usuario/registro/registro.module').then(m => m.RegistroPageModule)
    },

    {
        path: 'movimentacoes/solicitacao-creditos',
        loadChildren: () => import('./movimentacoes/solicitacao-credito/solicitacao-credito.module')
            .then(m => m.SolicitacaoCreditoPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'movimentacoes/transacoes',
        loadChildren: () => import('./movimentacoes/transacoes/transacoes.module').then(m => m.TransacoesPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'movimentacoes/transferir',
        loadChildren: () => import('./movimentacoes/transferir/transferir.module').then(m => m.TransferirPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'movimentacoes/leitor-qrcode',
        loadChildren: () => import('./movimentacoes/leitor-qrcode/leitor-qrcode.module').then(m => m.LeitorQrCodePageModule),
        canActivate: [AuthGuardService]
    },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'mapa-nativo',
    loadChildren: () => import('./mapa-nativo/mapa-nativo.module').then( m => m.MapaNativoPageModule)
  },
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
