import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    aplicativoInstalado: boolean = true;
    tipoDispositivo;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar
    ) {
        this.initializeApp();

        // if (/Android|iPhone|iPad/i.test(navigator.userAgent)) {
        //     if (/Android/i.test(navigator.userAgent))
        //         this.tipoDispositivo = 'android';
        //     else if (/iPhone|iPad/i.test(navigator.userAgent))
        //         this.tipoDispositivo = 'ios';

        //     if (!((window.navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches)) {
        //         this.aplicativoInstalado = false;
        //     }
        // }
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    instalarAplicativo() {
        window['pwaInstallApp']();
    }
}
