import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { environment } from './../../../environments/environment';
import { UsuarioModel } from './../../models/usuario';

export enum SignInResponseEnum {
    SUCCESS, INTERNAL_ERROR, INVALID
}

export interface JwtAuth {
    token: string;
    usuario: UsuarioModel
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private jwtAuth: JwtAuth = null;
    private _isLoadingToken = false;

    constructor(private http: HttpClient, private storage: Storage) {
        this.loadTokenFromStorage().then(() => { });
    }

    get token(): string { return this.jwtAuth ? this.jwtAuth.token : null; }

    get usuarioAtual(): UsuarioModel { return this.jwtAuth ? this.jwtAuth.usuario : null; }

    async usuarioAtualAsync() {
        const jwtAuth: JwtAuth = await this.storage.get('jwt');

        console.log(jwtAuth);

        if (!jwtAuth)
            return null;

        return jwtAuth.usuario;
    }

    get isLoadingToken(): boolean { return this._isLoadingToken; }

    loadTokenFromStorage(): Promise<JwtAuth> {
        this._isLoadingToken = true;

        return new Promise((result) => {
            this.storage.get('jwt').then((jwtAuth: JwtAuth) => {
                this.jwtAuth = jwtAuth;
                this._isLoadingToken = false;
                result(jwtAuth);
            })
        });
    }

    invalidateToken() {
        this.jwtAuth = null;
        return this.storage.remove('jwt').then(() => { });
    }

    signIn(payload): Promise<SignInResponseEnum> {
        return new Promise((response) => {
            this.http.post(`${environment.apiUrl}/colaborador_api/autenticacao/entrar`, payload)
                .subscribe(
                    (payload: JwtAuth) => {
                        this.setToken(payload).then(() => response(SignInResponseEnum.SUCCESS));
                    },
                    (error: HttpErrorResponse) => {
                        response(error.status === 401 ? SignInResponseEnum.INVALID : SignInResponseEnum.INTERNAL_ERROR)
                    }
                )
        });
    }

    setToken(jwtAuth: JwtAuth) {
        this.jwtAuth = jwtAuth;
        return this.storage.set('jwt', jwtAuth);
    }
}
