import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.isLoadingToken) {
            // Se o token ainda está sendo carregado, então é preciso esperar, e logo em seguinda prosseguir com a requisição
            return this.waitForToken().pipe(mergeMap(() => {
                return this._intercept(req, next);
            }))
        }
        else
            return this._intercept(req, next);
    }

    /*
        Método notifica quando o token é carregado
    */
    private waitForToken(): Observable<any> {
        const delay = (result) => {
            setTimeout(() => {
                if (this.authService.isLoadingToken)
                    delay(result);
                else
                    result();
            }, 200);
        }

        return from(new Promise(delay));
    }

    private _intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.token != null)
            req = req.clone({
                setHeaders: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.authService.token}`,
                },
            });

        return next.handle(req).pipe(tap(() => { }, (error: any) => {
            if (!this.authService.isLoadingToken) {
                // Se a API retornou 401 - Não autorizado
                // Então é necessário invalidar o token JWT e solicitar a decisão para onde ir
                if (error instanceof HttpErrorResponse && error.status == 401)
                    this.authService.invalidateToken().then(() => {
                        this.router.navigate(['/']);
                    });
            }
        }));
    }
}