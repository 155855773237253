import { AuthService } from './../autenticacao/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    async canActivate() {
        if (!(await this.authService.usuarioAtualAsync())) {
            this.router.navigate(['usuario/login']);
            return false;
        }
        return true;
    }
}