<ion-app>
    <div id="container-main" *ngIf="!aplicativoInstalado">
        <div id="container">
            <img class="logo" src="/assets/images/icone.png" />

            <h4>Instale o aplicativo DuNordy</h4>
            na tela inicial para acessar as funcionalidades.

            <div id="ios-instructions" *ngIf="tipoDispositivo == 'ios'">
                1 – Basta clicar
                <img src="/assets/images/icone-ios-pwa.png" style="height: 37px; margin-bottom: -5px;" />
                na parte inferior da sua tela;

                <br>
                2 – Clique no
                <img src="/assets/images/icone-ios-pwa-add.png"
                    style="height: 37px; margin-bottom: -5px; margin-right: 3px;" />
                <b>Tela de início</b>;

                <br><br>
                3 – Clique em <b>Adicionar</b>.
            </div>

            <div style="margin: 0 auto; display: block; margin-top: 2em;" *ngIf="tipoDispositivo != 'ios'">
                <ion-button expand="full" color="medium" (click)="instalarAplicativo()">
                    INSTALAR
                </ion-button>
            </div>
        </div>
    </div>

    <ion-router-outlet></ion-router-outlet>
</ion-app>